
import { Vue, Component } from "vue-property-decorator";
import { DeploymentModel, DeploymentOverviewModel } from "@/api/generated";
import { getModule } from "vuex-module-decorators";
import { DataTableHeader } from "vuetify";
import nameof from "@/utility/nameof";
import api from "@/api";
import SnackbarModule from "@/store/snackbarModule";
import UserModule from "@/store/userModule";
import userRoleConstant from "@/Constants/userRoleConstant";

const snackbarModule = getModule(SnackbarModule);
const userModule = getModule(UserModule);

@Component
export default class DeploymentTable extends Vue {
  private itemsPerPage = 4;
  private loading = false;
  private showArchived = false;
  private isAdmin = false;
  private deployments: Array<DeploymentOverviewModel> = [];
  private upcomingDeployments: Array<DeploymentOverviewModel> = [];
  private endedDeployments: Array<DeploymentOverviewModel> = [];
  private closed = false;
  private headers: Array<DataTableHeader<DeploymentModel>> = [
    { text: "", value: "actions", sortable: false },
    { text: "Name", value: nameof<DeploymentModel>("name") },
    { text: "Venue", value: nameof<DeploymentModel>("venueName") },
    {
      text: "Organisation",
      value: nameof<DeploymentModel>("organisationName"),
    },
    { text: "Admin Pin", value: nameof<DeploymentModel>("adminPin") },
    {
      text: "Gross Sales",
      value: "grossRevenue",
    },
    {
      text: "Kiosk Count",
      value: "kioskCount",
    },
    {
      text: "Status",
      value: "status",
    },
    {
      text: "Scheduled Start",
      value: "scheduledStart",
    },
    {
      text: "Scheduled End",
      value: "scheduledEnd",
    },
  ];

  private async created() {
    await this.getAllActiveAndRecentDeployments();
    await this.getUpcomingDeployments();
    await this.adminPermissions();
  }

  private async adminPermissions() {
    this.isAdmin =
      userModule.userRole == userRoleConstant.systemAdministrator ||
      userModule.userRole == userRoleConstant.operationsManager;
    return this.isAdmin;
  }

  private get adminOnsiteUserPermissions() {
    return (
      userModule.userRole == userRoleConstant.systemAdministrator ||
      userModule.userRole == userRoleConstant.operationsManager ||
      userModule.userRole == userRoleConstant.onSiteUser
    );
  }

  private get onSiteUserPermissions() {
    return userModule.userRole == userRoleConstant.onSiteUser;
  }

  private get portfolioManagerPermissions() {
    return userModule.userRole == userRoleConstant.porfolioManager;
  }

  private get customerAdminPermissions() {
    return userModule.userRole == userRoleConstant.customerAdmin;
  }

  private get venueManagerPermissions() {
    return userModule.userRole == userRoleConstant.venueManager;
  }

  private async getAllActiveAndRecentDeployments() {
    try {
      this.loading = true;
      if (this.adminOnsiteUserPermissions) {
        const response =
          await api.DeploymentService.apiDeploymentActiveAndRecentGet();
        this.deployments = response.data;
      }
      if (this.portfolioManagerPermissions || this.customerAdminPermissions) {
        const response =
          await api.DeploymentService.apiDeploymentByorganisationvenueActiveAndRecentOrganisationIdGet(
            userModule.organisationId
          );
        this.deployments = response.data;
      }
      if (this.venueManagerPermissions) {
        const response =
          await api.DeploymentService.apiDeploymentByvenueActiveAndRecentVenueIdGet(
            userModule.venueId
          );
        this.deployments = response.data;
      }
    } catch {
      snackbarModule.setSnackbarMessage(
        "Failed to retrieve all active and recent deployments"
      );
    } finally {
      this.loading = false;
    }
  }

  private async getUpcomingDeployments() {
    try {
      this.loading = true;
      if (this.adminOnsiteUserPermissions) {
        const response = await api.DeploymentService.apiDeploymentUpcomingGet();
        this.upcomingDeployments = response.data;
      }
      if (this.portfolioManagerPermissions || this.customerAdminPermissions) {
        const response =
          await api.DeploymentService.apiDeploymentByorganisationvenueUpcomingGet(
            userModule.organisationId
          );
        this.upcomingDeployments = response.data;
      }
      if (this.venueManagerPermissions) {
        const response =
          await api.DeploymentService.apiDeploymentByvenueUpcomingVenueIdGet(
            userModule.venueId
          );
        this.upcomingDeployments = response.data;
      }
    } catch {
      snackbarModule.setSnackbarMessage(
        "Failed to retrieve current and upcoming deployments"
      );
    } finally {
      this.loading = false;
    }
  }

  private async loadAll() {
    try {
      this.loading = true;
      const response = await api.DeploymentService.apiDeploymentEndedGet();
      this.endedDeployments = response.data;
    } catch {
      snackbarModule.setSnackbarMessage(
        "Failed to retrieve all Ended Deployments"
      );
    } finally {
      this.loading = false;
    }
  }

  private async getEndedDeployments() {
    try {
      this.loading = true;
      if (this.adminOnsiteUserPermissions) {
        const response =
          await api.DeploymentService.apiDeploymentEndedLastThreeMonthsGet();
        this.endedDeployments = response.data;
      }
      if (this.portfolioManagerPermissions || this.customerAdminPermissions) {
        const response =
          await api.DeploymentService.apiDeploymentByorganisationvenueEndedGet(
            userModule.organisationId
          );
        this.endedDeployments = response.data;
      }
      if (this.venueManagerPermissions) {
        const response =
          await api.DeploymentService.apiDeploymentByvenueEndedVenueIdGet(
            userModule.venueId
          );
        this.endedDeployments = response.data;
      }
    } catch {
      snackbarModule.setSnackbarMessage("Failed to retrieve ended deployments");
    } finally {
      this.loading = false;
    }
  }

  private async closedDeploymentsRef() {
    this.closed = true;
    await this.getEndedDeployments();
  }

  private upcomingDeploymentsRef() {
    this.closed = false;
  }

  get deploymentsHeaders() {
    if (this.closed == true) {
      let duration = { text: "Duration", value: "duration" };
      let endedHeaders = this.headers.concat(duration);
      return endedHeaders;
    } else {
      return this.headers;
    }
  }

  get filteredUpcomingDeployments(): Array<DeploymentOverviewModel> {
    if (this.upcomingDeployments.length > 0) {
      return this.upcomingDeployments.filter(
        (x) => x.archived == this.showArchived
      );
    }
    return [];
  }

  get filteredClosedDeployments(): Array<DeploymentOverviewModel> {
    if (this.endedDeployments.length > 0) {
      return this.endedDeployments.filter(
        (x) => x.archived == this.showArchived
      );
    }
    return [];
  }

  private showProfile(item: any) {
    this.$router.push({
      name: "DeploymentProfile",
      params: { deploymentId: item.deploymentId },
    });
  }
}
